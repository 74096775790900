import { addUsercentricsConsentEventListener } from "./components/handle-usercentrics-buttons";

let isYouTubeVideoConsented = false;

addUsercentricsConsentEventListener((event) => {
  const allAlternativeTexts = document.querySelectorAll<HTMLElement>(
    ".youtube-alternative"
  );

  if (!event.detail["YouTube Video"] || isYouTubeVideoConsented) {
    for (const el of allAlternativeTexts) {
      el.hidden = false;
    }
    return;
  }

  Array.from(allAlternativeTexts).map((el) => {
    el.hidden = true;
  });

  const allYoutubeVideos =
    document.querySelectorAll<HTMLElement>(".youtube-iframe");

  for (const el of allYoutubeVideos) {
    const src = el.dataset["src"];
    src && el.setAttribute("src", src);
  }

  isYouTubeVideoConsented = true;
});
