import "../css/entry.css";
import { initCarousels } from "./components/carousel";
import { handleUsercentricsButtons } from "./components/handle-usercentrics-buttons";
import { initLightbox } from "./components/lightbox";
import { initNavbarToggler } from "./components/navbar-toggler";
import { handleScrollHeader } from "./components/scroll-header";
import { toggleTabs } from "./components/tabs";
import registerErrorReporting from "./error-reporting";
import "./main";

registerErrorReporting();
handleScrollHeader();
handleUsercentricsButtons();
toggleTabs();
initLightbox();
initCarousels();
initNavbarToggler();
