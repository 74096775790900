export const initNavbarToggler = () => {
  const navbarTogglerEl =
    document.querySelector<HTMLElement>(".js-navbar-toggler");
  const headerNav = document.querySelector<HTMLElement>(".nav");

  navbarTogglerEl?.addEventListener("click", () => {
    headerNav?.classList.toggle("active");
    navbarTogglerEl?.classList.toggle("active");
  });
};
