import GLightbox from "glightbox";

const addLightbox = (
  lightboxes: NodeListOf<HTMLElement>,
  options?: Partial<AllOptions>
) => {
  if (!lightboxes) return;

  Array.from(lightboxes).forEach((el) => {
    GLightbox({
      selector: "." + el.classList[0],
      touchNavigation: true,
      touchFollowAxis: true,
      loop: options?.loop ?? true,
    });
  });
};

export const initLightbox = () => {
  const tabLightbox =
    document.querySelectorAll<HTMLElement>(".js-tab-lightbox");
  const galleryLightbox = document.querySelectorAll<HTMLElement>(
    ".js-gallery-lightbox"
  );
  const accommodationLightbox = document.querySelectorAll<HTMLElement>(
    ".js-accommodation-lightbox"
  );

  addLightbox(tabLightbox);
  addLightbox(galleryLightbox);
  addLightbox(accommodationLightbox);
};
