import Embla from "embla-carousel";

const createCarousel = (mainContainer: HTMLElement) => {
  const mainElement = mainContainer.querySelector<HTMLElement>(".embla");

  if (!mainElement) return;

  const carousel = Embla(mainElement, {
    align: "start",
    loop: true,
  });

  const leftArrowElement =
    mainContainer.querySelector<HTMLElement>(".carousel-prev");
  const rightArrowElement =
    mainContainer.querySelector<HTMLElement>(".carousel-next");
  if (!leftArrowElement || !rightArrowElement) return;

  leftArrowElement.addEventListener("click", () => carousel.scrollPrev());
  rightArrowElement.addEventListener("click", () => carousel.scrollNext());
};

export const initCarousels = () => {
  const logosCarouselContainer =
    document.querySelector<HTMLElement>(".js-logos-carousel");
  const companiesCarouselContainer = document.querySelector<HTMLElement>(
    ".js-companies-carousel"
  );

  logosCarouselContainer && createCarousel(logosCarouselContainer);
  companiesCarouselContainer && createCarousel(companiesCarouselContainer);
};
