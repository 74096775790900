interface WindowWithUsercentrics extends Window {
  UC_UI?: {
    showFirstLayer: () => void;
    showSecondLayer: () => void;
    isInitialized: () => boolean;
  };
}

interface UsercentricsEvent extends Event {
  detail: {
    event: "consent_status";
    "YouTube Video"?: boolean;
  };
}

export const handleUsercentricsButtons = () => {
  const allButtons = document.querySelectorAll(
    '[href="#show-usercentrics-modal"]'
  );

  Array.from(allButtons).forEach((element) => {
    element.addEventListener("click", (event) => {
      event.preventDefault();
      (<WindowWithUsercentrics>(<unknown>window)).UC_UI?.showSecondLayer();
    });
  });
};

const getIsUsercentricsEvent = (event: Event): event is UsercentricsEvent =>
  (<UsercentricsEvent>event)?.detail?.event === "consent_status";

export const addUsercentricsConsentEventListener = (
  callback: (event: UsercentricsEvent) => void
) =>
  window.addEventListener(
    "UsercentricsCustomEvent",
    (event) => getIsUsercentricsEvent(event) && callback(event)
  );
