let lastScrollTopOffset = 0;

const fixHeader = (element: HTMLElement, scrollOverWindowHeight: boolean) => {
  const homePageCheck = element.classList.contains("transparent-header");

  element.classList.toggle(
    "header-fixed",
    !homePageCheck || scrollOverWindowHeight
  );
};

const collapseHeader = (element: HTMLElement, scrollTopOffset: number) => {
  if ((scrollTopOffset || lastScrollTopOffset) < 0) {
    element.classList.add("header-collapsed");
    return;
  }

  element.classList.toggle(
    "header-collapsed",
    scrollTopOffset <= lastScrollTopOffset
  );
};

const handleHeader = (element: HTMLElement) => {
  const navbar = document.querySelector<HTMLElement>(".nav");

  if (navbar?.classList.contains("active")) return;

  const scrollTopOffset = window.scrollY || document.documentElement.scrollTop;
  const scrollOverWindowHeight = scrollTopOffset >= 1;
  fixHeader(element, scrollOverWindowHeight);
  collapseHeader(element, scrollTopOffset);

  lastScrollTopOffset = scrollTopOffset;
};

export const handleScrollHeader = () => {
  const events = ["resize", "scroll"];
  const headerElement = document.querySelector<HTMLElement>(".js-main-header");

  if (!headerElement) return;

  for (let event of events) {
    window.addEventListener(event, () => handleHeader(headerElement));
  }
};
